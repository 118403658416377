import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function WebinarForm({ webinarCode, dates }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [sent, setSent] = useState(false);
  const onSubmit = async (data) => {
    try {
      await axios.post(
        "https://CHANNEL-PRO.rational-touch.com/channel/console/webinar",
        { ...data, webinarCode },
      );
      setSent(true);
    } catch (error) {
      console.error(error);
    }
  };

  const imgBg = webinarCode.includes("BMW")
    ? "https://images.unsplash.com/photo-1538690560694-c770d02e202c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.2&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
    : "https://images.unsplash.com/photo-1532578498858-e21a39e0a449?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply";

  const RequiredText = () => (
    <p className="mt-2 text-sm text-center tracking-tight text-red-800 sm:text-sm">
      Este campo es requerido
    </p>
  );

  if (sent) {
    return (
      <div className="bg-white py-24 sm:py-12 ">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-7xl lg:text-center border-2 border-slate-200 rounded-xl">
            <p
              className="mt-8 mb-8 font-bold tracking-tight  sm:text-2xl bg-gradient-to-r from-green-400 via-orange-500 to-indigo-500 bg-[length:100%_6px] text-transparent bg-clip-text"
              id="que-es"
            >
              Gracias por registrarte en nuestro webinar. Nos vemos pronto!
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <img
        src={imgBg}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      />
      <svg
        viewBox="0 0 1097 845"
        aria-hidden="true"
        className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
      >
        <path
          fill="url(#10724532-9d81-43d2-bb94-866e98dd6e42)"
          fillOpacity=".7"
          d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
        />
        <defs>
          <linearGradient
            id="10724532-9d81-43d2-bb94-866e98dd6e42"
            x1="1097.04"
            x2="-141.165"
            y1=".22"
            y2="363.075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1102d9" />
            <stop offset={1} stopColor="#40ae55" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1097 845"
        aria-hidden="true"
        className="absolute left-1/2 -top-52 -z-10 w-[68.5625rem] -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0"
      >
        <path
          fill="url(#8ddc7edb-8983-4cd7-bccb-79ad21097d70)"
          fillOpacity=".5"
          d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
        />
        <defs>
          <linearGradient
            id="8ddc7edb-8983-4cd7-bccb-79ad21097d70"
            x1="1097.04"
            x2="-141.165"
            y1=".22"
            y2="363.075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1102d9" />
            <stop offset={1} stopColor="#40ae55" />
          </linearGradient>
        </defs>
      </svg>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
        <p
          className="mt-8 text-xl font-bold tracking-tight text-white sm:text-4xl"
          id="contacto"
        >
          ¿Quieres asistir a nuestro Webinar {webinarCode}?
        </p>
        <p className="mt-8 text-xl tracking-tight text-white sm:text-2xl">
          Rellena el siguiente formulario para inscribirte
        </p>
        <div className="flex justify-center mt-5">
          <div className="mb-3 xl:w-96">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register("name", { required: true })}
                type="text"
                className="
            form-control
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-orange-500 focus:outline-none
            "
                id="exampleFormControlInput1"
                placeholder="Nombre y Apellidos"
              />
              {errors.name && <RequiredText />}
              <input
                {...register("email", { required: true })}
                type="email"
                className="
            mt-5
            form-control
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-slate-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-orange-500 focus:outline-none
            "
                id="exampleFormControlInput1"
                placeholder="Email"
              />
              {errors.email && <RequiredText />}
              <input
                {...register("phone", { required: true })}
                type="phone"
                className="
            mt-5
            form-control
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-slate-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-orange-500 focus:outline-none
            "
                id="exampleFormControlInput1"
                placeholder="Teléfono"
              />
              {errors.phone && <RequiredText />}

              <input
                type="text"
                placeholder="Empresa"
                {...register("company", { required: true })}
                className="
            mt-5
            form-control
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-slate-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-orange-500 focus:outline-none
            "
              />
              {errors.company && <RequiredText />}
              <select
                {...register("date", { required: true })}
                className="
            mt-5
            form-control
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-slate-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-orange-500 focus:outline-none
            "
              >
                {dates.map((d) => (
                  <option key={webinarCode + d} value={d}>
                    {d}
                  </option>
                ))}
              </select>
              {errors.date && <RequiredText />}

              <button
                type="button"
                onClick={() => handleSubmit(onSubmit)()}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className="inline-block px-6 py-2.5 mt-10 bg-orange-500 text-white font-medium text-xl leading-tight uppercase rounded shadow-md hover:bg-gray-400 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
