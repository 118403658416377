import React from "react";

import {
  ArrowPathRoundedSquareIcon,
  ArrowTrendingDownIcon,
  FaceFrownIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Atención telefónica",
    description:
    "Entre el 40 y el 70% de las llamadas no pudieron ser atendidas por concesionarios y talleres",
    icon: PhoneIcon,
  },
  {
    name: "Reintentos",
    description:
    "Los clientes para ser atendidos, tienen que llamar una media de 2,4 veces para conseguir ser atendidos.",
    icon: ArrowPathRoundedSquareIcon,
  },
  {
    name: "Pérdida de clientes",
    description:
    "Entre el 15 y el 20% de los clientes no consiguen contactar y después de 2 intentos no vuelven a llamar.",
    icon: FaceFrownIcon,
  },
  {
    name: "Impacto en los resultados",
    description:
    "La inmediatez de la comunicación con el cliente y su nivel de satisfacción con la atención recibida impactan entre un 15 y 20% en las ventas, además de impactar en los resultados del NPS y calidad.",
    icon: ArrowTrendingDownIcon,
  },
];

export default function FeatureSectionOne() {
  return (
    <div className="bg-gradient-to-b from-slate-800 via-slate-700 to-slate-800 py-24 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
            ¿Por qué nace Woice Auto?
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-400 lg:text-justify">
            Atender las llamadas e intentos de contacto de los clientes con el
            concesionario es complicado.{" "}
            <strong>No siempre se puede atender al cliente</strong>{" "}
            en el momento que trata de contactar con la concesión, lo que en
            muchos casos supone que el cliente tenga que volver a llamar.{" "}
            <strong>Los datos demuestran que:</strong>{" "}
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-orange-500">
                  <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-slate-400">
                    <feature.icon
                      className="h-6 w-6 text-gray-100"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-400">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
