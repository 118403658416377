export default function AboutSectionOne() {
  return (
    <div className="bg-white py-24 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl lg:text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-orange-500">
            Más de 2.500 profesionales en España usan Woice Auto a diario
          </h2>
          <p
            className="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            id="que-es"
          >
            ¿Qué es Woice Auto?
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 lg:text-justify">
            Es un servicio de atención a clientes basado en{" "}
            <strong>Inteligencia Artificial</strong>, que gestiona el{" "}
            <strong>
              100% de las comunicaciones del cliente con el concesionario
            </strong>
            , ya sean llamadas telefónicas, WhatsApp, o desde los propios sitios
            web.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 lg:text-justify">
            <strong>Woice Auto</strong> gestiona tanto la recepción como la
            emisión de llamadas en tiempo real de forma{" "}
            <strong>fluida y eficiente, a través de un leguaje natural</strong>{" "}
            24 horas al día, 365 días al año, de manera autónoma e inteligente,
            asegurando que todos los clientes son atendidos en tiempo y forma,
            de acuerdo con los propios estándares de marca y KPI´s fijados
          </p>
          <button class="m-4 p-1 rounded-full from-green-400 via-orange-500 to-indigo-500 bg-gradient-to-r mt-28 ">
            <span class="block text-black px-4 py-2 font-semibold rounded-full bg-white">
              <span className="text-slate-600">Disponible en 3 idiomas:</span>{" "}
              Español, Catalán, Inglés
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
