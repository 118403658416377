import { createHashRouter, RouterProvider } from "react-router-dom";
import React from "react";

import "./App.css";
import HeaderSection from "./components/header-section";
import FeatureSectionOne from "./components/feature-section-2";
import AboutSectionOne from "./components/about-section-1";
import AboutSectionTwo from "./components/about-section-2";
import { Statistic } from "./components/stats-section";
import FeaturesWithImage from "./components/feature-section-image";
import { Footer } from "./components/footer";
import { FeatureSection4 } from "./components/feature-section-4";
import Contact from "./components/contact";
import FeaturesWithImageLeft from "./components/feature-section-image-left";
import HeaderMenu from "./components/header-menu";
import PrivacyPolicy from "./components/privacy-policy";
import WebinarForm from "./components/webinar-form";

const Landing = () => (
  <>
    <HeaderMenu />
    <HeaderSection />
    <AboutSectionOne />
    <FeaturesWithImage />
    <FeatureSectionOne />
    <AboutSectionTwo />
    <FeaturesWithImageLeft />
    <FeatureSection4 />
    <Statistic />
    <Contact />
    <Footer />
  </>
);

const router = createHashRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/privacy-policy",
    element: (
      <>
        <HeaderMenu />
        <PrivacyPolicy />
        <Footer />
      </>
    ),
  },
  {
    path: "webinar-llamadas-salientes",
    element: (
      <>
        <HeaderMenu />
        <WebinarForm
          webinarCode={"WOICE - LLamadas-Salientes"}
          dates={[
            "Día 23 de noviembre a las 16:30.",
          ]}
        />
        <Footer />
      </>
    ),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
