export default function AboutSectionOne() {
  return (
    <div className="bg-white py-24 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl lg:text-center">
          <div class="container mx-auto px-4 py-8">
            <h1 class="text-3xl font-semibold mb-4">
              Política de Privacidad de Woice
            </h1>
            <p class="text-gray-600 mb-4">Última actualización: {Date()}</p>

            <p>
              Bienvenido a Woice, la aplicación de gestión de concesionarios
              diseñada para simplificar y mejorar la eficiencia de su
              concesionario. En Woice, valoramos y respetamos su privacidad y
              nos comprometemos a proteger sus datos personales. Esta Política
              de Privacidad tiene como objetivo informarle sobre cómo
              recopilamos, utilizamos, compartimos y protegemos sus datos
              personales cuando utiliza nuestra aplicación y nuestros servicios.
            </p>

            <h2 class="text-2xl font-semibold mt-6 mb-2">
              1. Información que Recopilamos
            </h2>
            <p>
              Recopilamos información que usted nos proporciona directamente al
              registrarse o utilizar nuestra aplicación, que puede incluir,
              entre otros:
            </p>
            <ul class="list-disc ml-8">
              <li>
                Información de contacto, como su nombre, dirección de correo
                electrónico y número de teléfono.
              </li>
              <li>
                Información de la empresa, como el nombre y la ubicación de su
                concesionario.
              </li>
              <li>
                Información de vehículos, incluidos detalles sobre los vehículos
                que gestiona en la aplicación.
              </li>
              <li>
                Información de inicio de sesión, como nombre de usuario y
                contraseña.
              </li>
            </ul>

            <h2 class="text-2xl font-semibold mt-6 mb-2">
              2. Uso de la Información
            </h2>
            <p>Utilizamos la información recopilada para:</p>
            <ul class="list-disc ml-8">
              <li>
                Proporcionar y mantener nuestros servicios, incluida la gestión
                de vehículos y clientes.
              </li>
              <li>
                Comunicarnos con usted, responder a sus consultas y proporcionar
                asistencia técnica.
              </li>
              <li>Mejorar y personalizar su experiencia con la aplicación.</li>
              <li>
                Cumplir con nuestras obligaciones legales y proteger nuestros
                derechos legales.
              </li>
            </ul>

            <h2 class="text-2xl font-semibold mt-6 mb-2">
              3. Compartir Información
            </h2>
            <p>
              No compartiremos su información personal con terceros no
              afiliados, excepto en las siguientes circunstancias:
            </p>
            <ul class="list-disc ml-8">
              <li>Con su consentimiento explícito.</li>
              <li>
                Con proveedores de servicios de confianza que nos ayudan a
                ofrecer nuestros servicios.
              </li>
              <li>
                Cuando sea necesario para cumplir con obligaciones legales o
                regulaciones aplicables.
              </li>
            </ul>

            <h2 class="text-2xl font-semibold mt-6 mb-2">
              4. Seguridad de Datos
            </h2>
            <p>
              Tomamos medidas razonables para proteger su información personal
              contra el acceso no autorizado, la divulgación, la alteración o la
              destrucción. Sin embargo, ninguna transmisión de datos por
              Internet o sistema de almacenamiento es completamente seguro, por
              lo que no podemos garantizar la seguridad absoluta de sus datos.
            </p>

            <h2 class="text-2xl font-semibold mt-6 mb-2">5. Sus Derechos</h2>
            <p>
              Usted tiene derechos sobre sus datos personales, incluido el
              acceso, rectificación y eliminación de los mismos. Puede ejercer
              estos derechos poniéndose en contacto con nosotros a través de la
              información de contacto proporcionada a continuación.
            </p>

            <h2 class="text-2xl font-semibold mt-6 mb-2">
              6. Cambios en la Política de Privacidad
            </h2>
            <p>
              Nos reservamos el derecho de modificar esta Política de Privacidad
              en cualquier momento. Le notificaremos sobre cambios
              significativos mediante una notificación en la aplicación o por
              correo electrónico.
            </p>

            <h2 class="text-2xl font-semibold mt-6 mb-2">7. Contacto</h2>
            <p>
              Si tiene alguna pregunta o inquietud sobre nuestra Política de
              Privacidad o el tratamiento de sus datos personales, no dude en
              ponerse en contacto con nosotros a través de
              management@rationaltouch.com.
            </p>

            <p class="mt-6">
              Al utilizar la aplicación Woice, usted acepta los términos y
              condiciones de esta Política de Privacidad. Le recomendamos que la
              lea detenidamente y que la revise periódicamente para mantenerse
              informado sobre cómo protegemos su privacidad.
            </p>

            <p class="mt-6">
              Gracias por confiar en Woice para la gestión de su concesionario.
            </p>

            <p class="mt-6">Atentamente,</p>
            <p class="font-semibold">El equipo de Woice</p>
          </div>
        </div>
      </div>
    </div>
  );
}
