import React from "react";

export default function AboutSectionTwo() {
  return (
    <div className="bg-white py-24 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl ">
          <p className="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-center">
            ¿Cómo funciona Woice <span className="underline decoration-orange-500">Auto</span>?
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 lg:text-justify">
            Woice Auto atiende al cliente de forma fluida y eficiente, a través
            de leguaje natural, comenzando con una pregunta abierta{" "}
            <strong>
              (“Buenos días Sr. Gómez, bienvenido a Oeste Motor, ¿en qué puedo
              ayudarle?”)
            </strong>
            , y comprender lo que necesita el cliente (pedir una cita, consultar
            el horario, hablar con un comercial, pedir un recambio, …) y, a
            partir de ahí:
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 lg:text-justify">
            Dar la información que necesita el cliente de manera autónoma,
            transferir una llamada a la persona adecuada, … pero lo más
            importante que hace es asegurarse de que todos los clientes estén
            atendidos y que no se pierda ninguna llamada ni oportunidad de
            negocio, aún cuando la persona que tiene que atender al cliente no
            esté disponible por cualquier motivo.
          </p>
        </div>
      </div>
    </div>
  );
}
