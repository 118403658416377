import React from "react";

export const Statistic = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <p className="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center mb-20">
        Impacto de Woice{" "}
        <span className="underline decoration-orange-600">Auto</span> en cifras
      </p>
      <div className="grid gap-8 sm:grid-cols-1 lg:grid-cols-3">
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <svg
              className="w-8 h-8 text-deep-purple-accent-400 sm:w-10 sm:h-10"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="text-4xl font-bold text-deep-purple-accent-400">
            + de 3 millones
          </h6>
          <p className="mb-2 font-bold text-md">
            de llamadas y conversaciones gestionadas por WhatsApp
          </p>
          <p className="text-gray-600 text-sm">
            <ul>Taller.</ul>
            <ul>Ventas.</ul>
            <ul>Recambios.</ul>
            <ul>Administración.</ul>
            <ul>Marketing.</ul>
          </p>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <svg
              className="w-8 h-8 text-deep-purple-accent-400 sm:w-10 sm:h-10"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="text-4xl font-bold text-deep-purple-accent-400">
            + de 500.000
          </h6>
          <p className="mb-2 font-bold text-md">
            Contactos realizados a clientes de manera predictiva
          </p>
          <p className="text-gray-600 text-sm">
            <ul>Retoma de vehículos.</ul>
            <ul>Recuperación de clientes para ventas y taller.</ul>
            <ul>Gestión proactiva de operaciones de mantenimiento.</ul>
            <ul>Campañas técnicas de marca.</ul>
            <ul>Campañas de marketing.</ul>
          </p>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <svg
              className="w-8 h-8 text-deep-purple-accent-400 sm:w-10 sm:h-10"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
          <h6 className="text-4xl font-bold text-deep-purple-accent-400">
            + de 1 millón
          </h6>
          <p className="mb-2 font-bold text-md">
            Consultas resueltas de manera autónoma
          </p>
          <p className="text-gray-600 text-sm">
            <ul>Citas de taller.</ul>
            <ul>Estado de vehículo.</ul>
            <ul>Gestiones administrativas.</ul>
            <ul>Precualificación de leads para ventas.</ul>
          </p>
        </div>
      </div>
    </div>
  );
};
