import { BoltIcon, ClockIcon, FaceSmileIcon } from "@heroicons/react/24/outline";
import React from "react";

export const FeatureSection4 = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 bg-gradient-to-b from-slate-100 via-slate-200 to-slate-100 rounded-md">
      <div className="max-w-7xl mb-10 sm:mx-auto">
        <h2 className="font-sans text-3xl font-bold leading-tight tracking-tight text-gray-900 sm:text-2xl sm:text-center">
          Woice Auto no es tener un buzón de voz, o un registro de llamadas
          perdidas,{" "}
          <span className="inline-block text-orange-500">sino que:</span>
        </h2>
      </div>
      <div className="grid gap-12 row-gap-8 lg:grid-cols-3">
        <div className="flex">
          <div className="mr-4">
            <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                <ClockIcon />
            </div>
          </div>
          <div>
            <p className="text-gray-900">
              Proporciona{" "}
              <span className="text-orange-500">
                un servicio al cliente de 24x7x365, ágil, eficaz, y
                personalizado
              </span>{" "}
              incluso en horas pico, o cuando el taller o ventas están cerrados.
            </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4">
            <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                <FaceSmileIcon />
            </div>
          </div>
          <div>
            <p className="text-gray-900">
              Hace que el cliente sea consciente de que{" "}
              <span className="text-orange-500">
                su gestión está ya en curso
              </span>{" "}
              no un simple{" "}
              <span className="text-orange-300 italic">
                “deje su mensaje tras oír la señal”
              </span>
              , sino que sepa que hemos{" "}
              <span className="text-orange-500">entendido lo que necesita</span>
              , que tenemos la información oportuna para poder{" "}
              <span className="text-orange-500">
                darle una respuesta de manera autónoma
              </span>{" "}
              por Woice Auto{" "}
              <span className="text-orange-300">
                sin que el cliente tenga que volver a llamar.
              </span>
            </p>
          </div>
        </div>
        <div className="flex">
          <div className="mr-4">
            <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                <BoltIcon />
            </div>
          </div>
          <div>
            <p className="text-gray-900">
              Permite gestionar{" "}
              <span className="text-orange-500">
                el taller y el departamento de ventas
              </span>{" "}
              de forma eficiente y de manera automática.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
