import React from "react";

export default function FeaturesWithImageLeft() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <img
            src="capture_2.png"
            alt="Product screenshot"
            className="w-[21rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[77rem] md:-mr-4 lg:-mr-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
